<template>
  <BCard
    :class="`${!resCalcPrice ? 'pt-1' : ''}`"
    no-body
  >
    <!-- SECTION - VN: Giá tạm tính, VU: Thông tin giá -->
    <template v-if="selectedTrips && !resCalcPrice && getSearchFlightArray">
      <BCard
        v-for="(dataSearchTrip, indexSearchTrip) of getSearchFlightArray"
        :key="indexSearchTrip"
        header-bg-variant="light-secondary"
        header-class="font-weight-bolder py-50"
        class="border mx-50"
        no-body
      >
        <template #header>
          <span class="text-body">
            {{ ['VN'].includes(airlineSearch) ? 'Giá tạm tính:' : 'Thông tin giá: ' }}
            {{ `${dataSearchTrip.startPoint}-${dataSearchTrip.endPoint} ${dataSearchTrip.departDate ? ` ${convertISODateTime(dataSearchTrip.departDate).dayAndMonth}` : '' }` }}
          </span>
        </template>

        <!-- ANCHOR - VN 1S cũ -->
        <template v-if="selectedTrips[indexSearchTrip] && selectedTrips[indexSearchTrip].every(Boolean) && dataTrips[indexSearchTrip]">
          <div v-if="['VN'].includes(airlineSearch) ? dataTrips[indexSearchTrip].isDomestic : true">
            <BTable
              small
              responsive
              :items="getDraftPriceTripSelected(selectedTrips[indexSearchTrip])"
              class="rounded mt-50"
              head-variant="success"
              thead-tr-class="text-nowrap"
              tbody-tr-class="text-nowrap text-right"
              :fields="['passenger', 'net', 'tax', 'total']"
            >
              <template
                v-for="(column, indexColumn) in [
                  'passenger',
                  'net',
                  'tax',
                  'total',
                ]"
                #[`head(${column})`]="data"
              >
                <div
                  :key="indexColumn"
                  :class="`${ indexColumn === 0 ? 'text-center' : 'text-right' }`"
                >
                  <span>{{ $t(`reservation.${data.column}`) }}</span>
                </div>
              </template>

              <template #cell(passenger)="{ item }">
                <div class="text-center">
                  <div class="font-weight-bolder text-nowrap">
                    {{ $t(`flight.${capitalize(item.paxType)}`) }} x1
                  </div>
                  <div class="d-flex flex-column flex-nowrap">
                    <template v-for="(fareCode, indexF) of item.fareBasisCode">
                      <small
                        v-if="fareCode"
                        :key="indexF"
                        class="fw-700"
                      >
                        ({{ fareCode }})
                      </small>
                    </template>
                  </div>
                </div>
              </template>
              <template #cell(net)="{ item }">
                <span class="font-weight-bolder">{{ Number(item.net) === 0 ? '-' : item.net }}</span>
              </template>
              <template #cell(tax)="data">
                <span
                  :id="`pax_tax_${data.index}_${data.item.paxType}_${data.item.fareBasisCode}_${indexSearchTrip}`"
                  class="font-weight-bolder"
                >
                  {{ Number(data.item.tax) === 0 ? '-' : data.item.tax }}
                </span>
                <BTooltip
                  v-if="Number(data.item.tax) !== 0"
                  :id="`tooltip-pax_tax_${data.index}_${data.item.paxType}_${data.item.fareBasisCode}_${indexSearchTrip}`"
                  :target="`pax_tax_${data.index}_${data.item.paxType}_${data.item.fareBasisCode}_${indexSearchTrip}`"
                  triggers="hover"
                  variant="info"
                  placement="top"
                  boundary="window"
                >
                  <div
                    :class="`font-italic text-white ${
                      isMobileView ? 'font-small-1' : 'font-small-3'
                    }`"
                  >
                    ({{ $t('flight.fareNotIncludeServiceFee') }})
                  </div>
                </BTooltip>
              </template>
              <template #cell(total)="data">
                <span
                  :id="`pax_total_${data.index}_${data.item.paxType}_${data.item.fareBasisCode}_${indexSearchTrip}`"
                  class="font-weight-bolder"
                >
                  {{ data.item.total }}
                </span>
                <BTooltip
                  :id="`tooltip-pax_total_${data.index}_${data.item.paxType}_${data.item.fareBasisCode}_${indexSearchTrip}`"
                  :target="`pax_total_${data.index}_${data.item.paxType}_${data.item.fareBasisCode}_${indexSearchTrip}`"
                  triggers="hover"
                  variant="info"
                  placement="top"
                  boundary="window"
                >
                  <div
                    :class="`font-italic text-white ${
                      isMobileView ? 'font-small-1' : 'font-small-3'
                    }`"
                  >
                    ({{ $t('flight.fareNotIncludeServiceFee') }})
                  </div>
                </BTooltip>
              </template>
            </BTable>
            <BAlert
              :show="['VN'].includes(airlineSearch)"
              variant="danger"
              class="mb-75 py-50 px-1 text-center"
            >
              <span class="text-danger fw-700 font-italic">{{ $t("flight['Please calculate the price to get the exact price']") }}</span>
            </BAlert>
          </div>
          <div
            v-else
            class="ml-1 mt-1 mb-2 font-italic text-danger font-weight-bolder d-block"
          >
            {{ $t('flight.plsCalcFlight') }}
          </div>
        </template>

        <div
          v-else
          class="ml-1 mb-50 font-italic text-danger font-weight-bolder d-block"
        >
          {{ $t('flight.notSelectedFlights') }}
        </div>
      </BCard>

      <!-- ANCHOR - Tổng tiền vé -->
      <BCard
        v-if="['VU'].includes(airlineSearch) && isSelectedAll"
        header-bg-variant="light-success"
        header-class="font-weight-bolder py-50"
        class="border mx-50 mt-2"
        no-body
      >
        <template #header>
          <div class="w-100 d-flex justify-content-around align-items-center font-medium-2">
            <span class="text-body"> {{ $t("flight['Total ticket price']") }} </span>
            <span
              class="text-danger fw-700"
              :class="isMobileView ? 'font-medium-2' : 'font-medium-3'"
            >
              {{ getTotalPriceTripSelectVU(selectedTrips).totalPriceAllPax }}
            </span>
          </div>
        </template>
      </BCard>
    </template>

    <!-- TODO - TÍNH GIÁ OK: Thông tin tính giá -->
    <b-tabs
      v-if="resCalcPrice"
      class="p-50"
      nav-class="bg-gray"
      pills
    >
      <b-tab
        :title="$t(`flight['Pricing information']`)"
        title-item-class="fw-700"
        active
      >
        <!-- ANCHOR - Không có error res -->
        <BCard
          v-for="(trip, indexTrip) of resCalcPrice"
          :key="indexTrip"
          header-bg-variant="light-info"
          header-class="font-weight-bolder text-airline font-medium-2 py-50"
          class="border mb-2"
          no-body
        >
          <template #header>
            <div>
              <span>{{ $t('reservation.itinerary') }} </span>
              <span
                v-if="
                  storeSearchFlight &&
                    !['RT'].includes(storeSearchFlight.type)
                "
              >
                #{{ trip.tripIndex + 1 }} </span>:
              <span>{{ trip.flights }}</span>
            </div>
          </template>

          <template v-if="['success'].includes(trip.status)">
            <BTable
              small
              responsive
              :items="trip.res"
              class="mb-0 mt-25"
              thead-tr-class="text-nowrap"
              tbody-tr-class="text-nowrap text-right"
              :fields="['passenger', 'net', 'tax', 'total']"
            >
              <template
                v-for="(column, indexColumn) in [
                  'passenger',
                  'net',
                  'tax',
                  'total',
                ]"
                #[`head(${column})`]="data"
              >
                <div
                  :key="indexColumn"
                  :class="`${
                    indexColumn === 0 ? 'text-center' : 'text-right'
                  }`"
                >
                  <span>{{ $t(`reservation.${data.column}`) }}</span>
                </div>
              </template>

              <template #cell(passenger)="{ item }">
                <div class="text-center">
                  {{
                    $t(
                      `flight.${capitalize(item.passengerRequested.paxType)}`,
                    )
                  }}
                  x1
                </div>
                <div class="text-center text-truncate">
                  {{ item.fareInfos.map((f) => f.fareBasisCode).join(', ') }}
                </div>
              </template>
              <template #cell(net)="{ item }">
                <span class="font-weight-bolder">{{
                  formatCurrency(item.basePrice, trip.currencyType)
                }}</span>
              </template>
              <template #cell(tax)="data">
                <span
                  :id="`pax_tax_${data.index}_${data.item.basePrice}`"
                  class="font-weight-bolder"
                >
                  {{ formatCurrency(data.item.finalTotalTaxes, trip.currencyType) }}
                </span>
                <!-- <BTooltip
                    :id="`tooltip-pax_tax_${data.index}_${data.item.paxType}_${data.item.fareBasisCode}_${indexSearchTrip}`"
                    :target="`pax_tax_${data.index}_${data.item.paxType}_${data.item.fareBasisCode}_${indexSearchTrip}`"
                    triggers="hover"
                    variant="info"
                    placement="top"
                    boundary="window"
                  >
                    <div
                      :class="`font-italic text-white ${isMobileView ? 'font-small-1' : 'font-small-3'}`"
                    >
                      (Chưa bao gồm phí dịch vụ)
                    </div>
                  </BTooltip> -->
              </template>
              <template #cell(total)="data">
                <span
                  :id="`pax_total_${data.index}_${data.item.finalTotalPrices}`"
                  class="font-weight-bolder text-airline"
                >
                  {{ formatCurrency(data.item.finalTotalPrices, trip.currencyType) }}
                </span>
              </template>
            </BTable>
          </template>

          <div
            v-else
            class="ml-1 py-50 font-italic text-danger"
          >
            {{ $t('flight.errCalcFlight') }}
          </div>
        </BCard>
        <BAlert
          v-if="resCalcPrice.every((t) => t.status === 'success')"
          variant="secondary"
          show
          class="px-1 py-1 mt-1 mb-0 font-weight-bold"
        >
          <div class="mb-50 font-medium-2 text-danger fw-700">
            TỔNG TIỀN VÉ
          </div>

          <BTable
            small
            responsive
            :items="getTotalAllTripPriceCalc(resCalcPrice)"
            class="rounded border"
            head-variant="success"
            thead-tr-class="text-nowrap text-right"
            tbody-tr-class="text-nowrap text-right"
            :fields="['totalNet', 'totalTax', 'totalPrice']"
          >
            <template
              v-for="(column, indexColumn) in ['totalNet', 'totalTax', 'totalPrice']"
              #[`head(${column})`]="data"
            >
              <div :key="indexColumn">
                <span>{{ $t(`flight.searchClass.${data.column}`) }}</span>
              </div>
            </template>

            <template #cell(totalNet)="{ item }">
              <span>{{ item.net }}</span>
            </template>
            <template #cell(totalTax)="{ item }">
              <span>{{ item.tax }}</span>
            </template>
            <template #cell(totalPrice)="{ item }">
              <span class="text-danger fw-700">{{ item.total }}</span>
            </template>
          </BTable>
        </BAlert>
      </b-tab>

      <template v-if="airlineSearch === 'VN1A'">
        <b-tab
          v-if="resCalcPrice?.[0]?.res?.[0]?.fareDetails"
          title="Thông tin giá"
          title-item-class="fw-700 border-left-secondary"
        >
          <ShortenBooking :shorten-text="resCalcPrice[0].res[0].fareDetails" />
        </b-tab>
        <b-tab
          v-if="resCalcPrice?.[0]?.res?.[0]?.fareRules"
          title="Điều kiện"
          title-item-class="fw-700 border-left-secondary"
        >
          <ShortenBooking :shorten-text="resCalcPrice[0].res[0].fareRules" />
        </b-tab>
        <b-tab
          v-if="resCalcPrice?.[0]?.res?.[0]?.bagInfo"
          title="Hành lý"
          title-item-class="fw-700 border-left-secondary border-right-secondary"
        >
          <ShortenBooking :shorten-text="resCalcPrice[0].res[0].bagInfo" />
        </b-tab>
      </template>
    </b-tabs>
  </BCard>
</template>

<script>
import {
  BCard, BTable, BAlert, VBTooltip, BTooltip, BTabs, BTab,
} from 'bootstrap-vue'

import useClassBookingHandle from '@flightv2/result-class-booking/useClassBookingHandle'

export default {
  components: {
    BCard,
    BTable,
    BAlert,
    BTooltip,
    BTabs,
    BTab,

    ShortenBooking: () => import('./ShortenBooking.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    getSearchFlightArray: {
      type: Array,
      default: () => [],
    },
    reservationsData: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const {
      isEmpty,
      capitalize,
      dataTrips,
      airlineSearch,
      storeSearchFlight,
      resCalcPrice,
      selectedTrips,
      isSelectedAll,
      convertShortTrip,
      formatCurrency,
      convertISODateTime,
      convertISODateTimeLangVN,
      getTotalAllTripPriceCalc,
      getDraftPriceTripSelected,
      getTotalPriceTripSelectVU,
    } = useClassBookingHandle()

    return {
      resCalcPrice,
      airlineSearch,
      selectedTrips,
      capitalize,
      storeSearchFlight,
      dataTrips,
      isEmpty,
      isSelectedAll,
      convertShortTrip,
      formatCurrency,
      convertISODateTime,
      getTotalAllTripPriceCalc,
      convertISODateTimeLangVN,
      getTotalPriceTripSelectVU,
      getDraftPriceTripSelected,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
